function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
var csrftoken = getCookie('csrftoken');
function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
$.ajaxSetup({
    beforeSend: function(xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
        }
    }
});
function getFormData($form){
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};
    $.map(unindexed_array, function(n, i){
        indexed_array[n['name']] = n['value'];
    });
    return indexed_array;
}
function checkForm(form, erro, sucesso) {
    /*Retira espaço vazio*/
    function trim(str) {
       return str.replace(/^\s+|\s+$/g,"");
    }
    function valid_email(str){
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(str)
    }
    dados = {};
    erros = [];
    valid = [];
    $.each(form.find("input, textarea"), function() {
        $this = $(this);
        if ($this.attr("id") == "recaptcha_challenge_field") {
            dados.challenge = $this.val();
        } else if ($this.attr("id") == "recaptcha_response_field") {
            dados.response = $this.val();
        } else {
            if (($this.data("email") == 1) && (!valid_email($this.val()))) {
                erros.push($this.data("verbose"));
                valid.push('invalid_email');
            }
            if ((trim($this.val()) == "" || $this.val() == "- selecione -" || $this.val() == "DDD") && $this.data("req") == 1) {
                erros.push($this.data("verbose"));
                valid.push($this.attr("id"));
            }
            dados[$this.data("verbose")] = $this.val();
        }
    });
    if (erros.length > 0) {
        //erro(erros);
        erro(valid);
    } else {
        sucesso(dados);
    }
}
function tryParseJSON(jsonString) {
    try {
        var o = JSON.parse(jsonString);
        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns 'null', and typeof null === "object", 
        // so we must check for that, too.
        if (o && typeof o === "object" && o !== null) {
            return o;
        }
    }
    catch (e) { }

    return false;
};

$(document).ready(function () {
    var BOTAO_MOSAICO_ID = "mosaico-next-page",
        BOTAO_VIDEOS_ID = "videos-next-page";

    var vincularEventoAoBotaoMaisVideos = function (seletorBotao) {
        seletorBotao.on("click", function (e) {
            videosProximaPagina($(e.target));
        });
    };

    vincularEventoAoBotaoMaisVideos($("#" + BOTAO_VIDEOS_ID));

    var videosProximaPagina = function (seletorBotao) {
        seletorBotao.addClass("loading");
        $.get(seletorBotao.attr("href"))
            .done(function (data) {
                $("#videos-list-container").append(data);
            })
            .always(function () {
                // seletorBotao.removeClass("loading");
                seletorBotao.remove();
                vincularEventoAoBotaoMaisVideos($("#" + BOTAO_VIDEOS_ID));
            });
    };

	$("#" + BOTAO_MOSAICO_ID).on("click", function (e) {
        proximaPagina($(e.target).attr("next_page"));
	});

    var proximaPagina = function (nextPage) {
        consultarProximaPagina(nextPage, $("#" + BOTAO_MOSAICO_ID));
    };

    var consultarProximaPagina = function (nextPage, seletorBotao) {
        if ( ! nextPage ) {
            return;
        }
        seletorBotao.addClass("loading");
		$.get((seletorBotao.attr("data-urlmosaico") || window.location.pathname) + "?page=" + nextPage)
			.done(function (data) {
                setBotaoInativo(setNextPagetAttr(adicionarNoticias(data.split("=#*#*#*#="), $("#mosaico-noticias")), seletorBotao), seletorBotao);
			})
			.always(function () {
                seletorBotao.removeClass("loading");
			});
    };

    var adicionarNoticias = function (dados, seletor) {
        seletor.append(dados[0]);
        return dados;
    };

    var setNextPagetAttr = function (dados, seletor) {
        seletor.attr("next_page", dados[1] ? dados[1].trim() : "");
        return dados;
    };

    var setBotaoInativo = function (dados, seletor) {
        if ( ! dados[1] || ! dados[1].trim() ) {
            seletor.addClass("inativo");
        }
        return dados;
    };

});

