/*Custom scripts*/


// Accessibility increase / decrease font-size (call in button on accessibility bar )
var fontSize = 0;
function increaseFontSize() {
  fontSize < 4 &&
    ($.each($("p, span, a, h1, h2, h3, h4, h5, h6, li, label"), function (t, e) {
      var a = parseInt($(this).css("font-size"));
      (a = a + 1 + "px"),
        $(this).attr("style", "font-size:" + a + " !important");
    }),
    (fontSize += 1));
}

function decreaseFontSize() {
  -2 < fontSize &&
    ($.each($("p, span, a, h1, h2, h3, h4, h5, h6, li, label"), function (t, e) {
      var a = parseInt($(this).css("font-size"));
      (a = a - 1 + "px"),
	  $(this).attr("style", "font-size:" + a + " !important");
    }),
    (fontSize -= 1));
}

function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}



var FacilitacaoComercio = (function()
{
	function FacilitacaoComercio()
	{
		this.infoModal = {
			category: {}
			, device: {}
		};

		this.Init();
	}

    FacilitacaoComercio.prototype.Init = function()
	{
		$('.facilitacao__progress__range').tooltip('show');

		//add lingua PT no Parsley
		var dataLang = $('.facilitacao').attr('data-lang');
		if ( dataLang == 'pt') {
            $.getScript('/static/js/parsley-pt-br.js');
        }

		this.PageGradients();
		//this.PanelGridHeight();
		this.PanelIconsHeight();
		this.SetInfoModal();
		this.BugFix();
		if ( $("#facilitacao__modal__form").length > 0 )
		{
			this.FormValidation();
		}

	};

	FacilitacaoComercio.prototype.BugFix = function() {
        $(window).on('load',function () {
            $('.facilitacao__panel__img').css('height', '1').css('height', 'auto');
        });
    }

    FacilitacaoComercio.prototype.PageGradients = function()
	{

        $(".facilitacao__panel__title__front").each(function( index, item )
        {
			var color = $(item).data("color");
			var colorTiny = tinycolor( color );

			var bgBarProgress = $( $(".facilitacao__progress--small").get().reverse() ).eq( index );
			var bgDivAdjust = $( $(".facilitacao__arrow-card__before").get().reverse() ).eq( index );
            var bgArrow = $( $(".facilitacao__arrow-card__after").get().reverse() ).eq( index );
            var panelLinks = $(".facilitacao__panel").eq( index );

            $( panelLinks )
			.find(".facilitacao__panel__link")
			.css("color", color);

            $( panelLinks )
			.find(".facilitacao__panel__link__arrow")
			.css(
				{
                    border: "solid " + color
            		, "border-width": "0 2px 2px 0"
				}
			);

            $( bgDivAdjust )
			.css("background-color", color);

			$(window).on(
				"load"
				, function()
				{
					var cardArrowHeight = $(".facilitacao__arrow-card").eq( index ).outerHeight() / 2;

					$( bgArrow )
					.css(
						{
							display: "block"
							, "border-color": "transparent transparent transparent " + color
							, "border-width": cardArrowHeight + "px 0 " + cardArrowHeight + "px 20px"
						}
					);
				}
			);

			if ( colorTiny.isDark() )
			{
                var colorAlpha = colorTiny.brighten(5).toString();

                $( bgBarProgress )
				.css("background-color", colorAlpha);

				$( item )
				.siblings(".facilitacao__panel__title__behind")
				.css("background", "linear-gradient( 113deg, rgba(0,0,0,0) 40%, " + color + " 50% )");

                $(item)
				.css("background", "linear-gradient(to bottom, " +
                    colorAlpha +" 0%,"
                    + colorAlpha + " 50%," +
                    color + " 51%, " +
                    color + " 100%)"
                );

                return;
			}

            var colorAlpha = colorTiny.darken(5).toString();

            $( bgBarProgress )
			.css("background-color", colorAlpha);

			$( item )
			.siblings(".facilitacao__panel__title__behind")
			.css("background", "linear-gradient( 113deg, rgba(0,0,0,0) 40%, " + colorAlpha + " 50% )");

            $(item)
			.css("background", "linear-gradient(to bottom, " +
                color +" 0%,"
                + color + " 50%," +
                colorAlpha + " 51%, " +
                colorAlpha + " 100%)"
            );
        });
	};

    FacilitacaoComercio.prototype.SetInfoModal = function()
	{
		var self = this;

        $('#facilitacao__modal').on(
        	'show.bs.modal'
			, function (event)
			{
				var button = $(event.relatedTarget);

				self.infoModal.category.title = button.data("category-title");
                self.infoModal.category.color = button.data("category-color");
                self.infoModal.category.imagem = button.data("category-imagem");

                self.infoModal.device.id = button.data("device-id");
                self.infoModal.device.title = button.data("device-title");
                self.infoModal.device.text = button.data("device-text");
                self.infoModal.device.review = button.data("review-industry");

				var modal = $(this);

				self.ProccessInfoModal( modal );
				self.ModalTitleGradient();
        	}
        );

        $('#facilitacao__modal').on(
            'hidden.bs.modal'
            , function ()
            {
                $("#facilitacao__modal__device-content, #facilitacao__modal__review-content")
				.removeClass("show")
            	.addClass("hidden");
			}
		);
	};

    FacilitacaoComercio.prototype.ProccessInfoModal = function( modal )
	{
		var self = this;

		var deviceTextTemplate = $(this.infoModal.device.text)
								 .addClass( "facilitacao__modal__text" );

        var reviewTextTemplate = $(this.infoModal.device.review)
            					 .addClass( "facilitacao__modal__text" );


        $(modal).find(".facilitacao__modal__title__content")
		.css("color", this.infoModal.category.color);

        $(modal).find(".facilitacao__modal__title__bar")
		.css("background-color", this.infoModal.category.color);

        $(modal).find(".facilitacao__modal__loading")
		.css("color", this.infoModal.category.color);


		$(modal).find("#facilitacao__modal__category-title")
		.text( this.infoModal.category.title );


		if ( this.infoModal.device.title.length && this.infoModal.device.text.length )
		{
			$("#facilitacao__modal__device-content")
			.removeClass("hidden")
			.addClass("show");

            $(modal).find("#facilitacao__modal__device-title")
			.text( this.infoModal.device.title );

            $(modal).find("#facilitacao__modal__device-text")
			.html( deviceTextTemplate );
		}

        if ( this.infoModal.device.review.length )
		{
            $("#facilitacao__modal__review-content")
			.removeClass("hidden")
			.addClass("show");

            $(modal).find("#facilitacao__modal__industry-review-text")
			.html( reviewTextTemplate );
		}
	};

    FacilitacaoComercio.prototype.ModalTitleGradient = function()
	{
		var self = this;

		$(".facilitacao__modal__main-title, .facilitacao__modal__submit")
		.each( function( index, item )
		{
			var color = self.infoModal.category.color;
            var colorTiny = tinycolor( color );

            if ( colorTiny.isDark() )
            {
				var colorAlpha = colorTiny.brighten(5).toString("rgb");

                $(item).css(
					{
						"background": "linear-gradient(to bottom, " +
							colorAlpha +" 0%,"
							+ colorAlpha + " 50%," +
							color + " 51%, " +
							color + " 100%)"
						, "text-shadow": "2px 2px 0px " + colorAlpha
					}
                );

                return;
            }

            var colorAlpha = colorTiny.darken(5).toString();

			$(item).css(
				{
					"background": "linear-gradient(to bottom, " +
						color +" 0%,"
						+ color + " 50%," +
						colorAlpha + " 51%, " +
						colorAlpha + " 100%)"
					, "text-shadow": "2px 2px 0px " + color
				}
			);
		});
	};

    FacilitacaoComercio.prototype.PanelGridHeight = function()
	{
		$(window).on(
			"load"
			, function()
			{
				$(".facilitacao__panel__header").each(function( index, item )
				{
					var panelHeaderHeight =  $(item).outerHeight();
					var panelItemsHeight = $(".facilitacao__panel__itens").eq(index).outerHeight();

					if ( (panelHeaderHeight - panelItemsHeight) > 0 )
					{
						$(".facilitacao__panel__itens")
						.eq(index)
						.css("height", panelHeaderHeight + "px");

						return;
					}

					$( item )
					.css("height", panelItemsHeight + "px");
				});
			}
		);
	};

	FacilitacaoComercio.prototype.PanelIconsHeight = function()
	{
		$(".facilitacao__panel").each( function( panelIndex, panelItem )
		{
			var majorHeight = 0;

			$(panelItem)
			.find(".facilitacao__panel__item")
			.each( function( iconIndex, iconItem )
			{
				if ( $(iconItem).height() > majorHeight )
				{
					majorHeight = $(iconItem).height();
				}
			});

			$(panelItem)
			.find(".facilitacao__panel__item")
			.css("height", majorHeight + "px");
		});
	};

    FacilitacaoComercio.prototype.FormValidation = function()
	{
		var self = this;

        var options =  {
            onKeyPress: function( phone, e, field, options )
            {
                var masks = ['(00) 0000-00000', '(00) 00000-0000'];
                var mask = (phone.length === 15) ? masks[1] : masks[0];
                $('#facilitacao__modal__input-phone').mask(mask, options);
            }
        };

        $("#facilitacao__modal__input-phone").mask('(00) 0000-0000', options);

        $("#facilitacao__modal__form").parsley(
            {
			   errorClass: "has-error"
                , successClass: "has-success"
                , errorsWrapper: "<div class='facilitacao__modal__feedback'></div>"
                , errorTemplate: "<p class='text-danger'>"
				, classHandler: function (el)
				{
                    return el.$element.closest('.form-group');
                }
            }
        ).on("form:submit", function()
		{
            var data = new FormData();

            data.append("dispositivo_id", self.infoModal.device.id);
			data.append("csrfmiddlewaretoken", $("#facilitacao__modal__form input[name='csrfmiddlewaretoken']").val());
            data.append("nome", $("#facilitacao__modal__input-nome").val());
            data.append("telefone", $("#facilitacao__modal__input-phone").val());
            data.append("email", $("#facilitacao__modal__input-email").val());
            data.append("empresa", $("#facilitacao__modal__input-empresa").val());
            data.append("descricao", $("#facilitacao__modal__input-descricao").val());
            data.append("orgao", $("#facilitacao__modal__input-orgao").val());
            data.append("anexo", document.querySelector("#facilitacao__modal__input-anexo").files[0]);

            $(".facilitacao__modal__loading")
			.removeClass("hidden")
			.addClass("visible-xs-inline-block visible-sm-inline-block visible-md-inline-block visible-lg-inline-block");

            $.ajax(
            	{
					url: "/infografico/salva-email-infografico"
					, cache: false
					, contentType: false
					, type: 'POST'
					, data: data
                    , processData: false
					, beforeSend: function()
					{
                        $("#facilitacao__modal__error")
						.removeClass("show")
						.addClass("hidden");
					}
					, success: function()
					{
                        $(".facilitacao__modal__loading")
						.removeClass("visible-xs-inline-block visible-sm-inline-block visible-md-inline-block visible-lg-inline-block")
						.addClass("hidden");

						$("#facilitacao__modal__success")
						.removeClass("hidden")
						.addClass("show");

						$('#facilitacao__modal__form')[0].reset();
						$("#facilitacao__modal__form").parsley().reset();
						$("#facilitacao__modal__form input[type='file']").val(null).change();

						setTimeout(function()
						{
                            $("#facilitacao__modal__success")
							.removeClass("show")
							.addClass("hidden");

						}, 5000);
					}
					, error: function()
					{
                        $(".facilitacao__modal__loading")
						.removeClass("visible-xs-inline-block visible-sm-inline-block visible-md-inline-block visible-lg-inline-block")
						.addClass("hidden");

                        $("#facilitacao__modal__error")
						.removeClass("hidden")
						.addClass("show");
					}
            	}
            );

			return false;
		});
	};

    FacilitacaoComercio.prototype.getCookie =  function(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

	return FacilitacaoComercio;
})();

$(document).ready(function () {

	$('html').addClass('acessibilidade');
  	$('p, p a').addClass('acessibilidade');
  	$('h2, h3, h4').addClass('acessibilidade');

	$(".btn-contrast").click(function () {
		$("html").toggleClass("hc"),
		sessionStorage.setItem(
			"alto-contraste",
			String($("html").hasClass("hc"))
		);
	}), "true" == sessionStorage.getItem("alto-contraste") && $("html").addClass("hc");

	var Facilitacao = new FacilitacaoComercio();

	$(function () {
	  $('[data-toggle="tooltip"]').tooltip();
	});

	$(document).on('click', '.canal-01-midias .tit', function(){
		$(this).toggleClass('active').next().slideToggle();
	});

    $(document).on('click', 'a.admin-sideframe', function(e) {
        e.preventDefault();
        new window.CMS.Sideframe().open({url: $(this).attr('href'), animate: true});
    });

    // Protege URL de bots. Obfusca URL e insere URL no href da âncora apenas no mouseenter e touchstart.
    // e.g. <a class="js-prime-hover" target="_blank" rel="nofollow" data-href="base64_encoded_URL" data-obf="true">text</a>
	$("a.js-prime-hover").on("mouseenter.jsPrimeHover touchstart.jsPrimeHover", function (e) {
		$el = $(this);
		var href = $el.attr("data-href");
		var obfuscated = JSON.parse($el.attr("data-obf") || false);
		href = obfuscated ? window.atob(href) : href; // decodifica url se necessário;
		$el.attr("href", href);
		$el.removeAttr("data-href");
		$(this).unbind('mouseenter.jsPrimeHover');
		$(this).unbind('touchstart.jsPrimeHover');
	});


	$(".js-print-page").on("click touchstart", function (e) {
		window.print();
	});

    // abre modal e carrega formulário lightbox (plugin)
    $( 'a[href*="formulariolightbox"]' ).bind( "click", function() {

        if( $('#lightbox-form-modal').length > -1 ) {
            $("#lightbox-form-modal").remove();
        }

        $("<div/>").attr('class','lightbox-form-container').appendTo('body');
        $("<div/>").attr('id','overlay').appendTo('body');

        var protocol= $(location).attr('protocol') + '//';
        var host    = $(location).attr('host');
        var path    = '/admin/si_formularios_lightbox/formulariolightbox/modal-form/';
        var href    = ($(this).attr("href")).split('/');
        var formId  = href.reverse()[0];
        var url     = protocol + host + path + formId;

        $('.lightbox-form-container').load(
            url,
            function () {
                $("#overlay").remove();
                $('#lightbox-form-modal').modal('show');
            }
        );

        event.preventDefault();
	});

	//previne de entrar no mapa na navegação pelo teclado vai direto pro select
	var mapLink = $(".map-holder").find("svg").find("a");

	mapLink.each(function () {
	  $(this).attr("tabindex", "-1");
	});

});

var PortalIndustria = (function(window,$){
	var _w = window
		, _d = window.document
		, _b = _d.body
		, _b = $(_b)
		, _PortalIndustria = {};

    $(_d).ready(function(){
        _PortalIndustria.InitLibs = !function(){

            var multiMenu = _b.find('#menu')
            , _init = function() {
                multiMenu.slinky();
            }
            return {
                init: _init
            }

	    }().init();
    });


	_PortalIndustria.Navigation = !function(){
		var btnMenu = _b.find('#mobMenu')
		, menuBreakPoint = 767
		, topMenuHeight = 49
		, menuIc = btnMenu.find('a')
		, menu = _b.find('.mobile-menu')
		, _openMenu = function() {
			btnMenu.click(function(){
				menu.toggle('slide', 'left', 300);
				menuIc.html(menuIc.find('i').hasClass('fa-bars') ? '<i class="fa fa-close"></i>' : '<i class="fa fa-bars"></i>');
			});
		}
		, _menuHeight = function() {
			var	mh = $(_w).height()-topMenuHeight;
			menu.height(mh);

			$('.bar-top .bt').hover(
			 function() {
				$(this).find('ul').collapse('show');
			  }, function() {
				$(this).find('ul').collapse('hide');
			  }
			);

		}
		, _preventHover = function() {
			if ("ontouchstart" in document.documentElement) {
				document.documentElement.className += " touch";
			}
			jQuery('.touch .hover-container').each(function() {
				var div = jQuery(this);
				div.hover(function() {
					div.removeClass('no-hover');
				});
				jQuery('*').not(div).bind('click', function() {
					div.addClass('no-hover');
				});
			});
		}
		, _onResize = function() {
			$(_w).resize(function(){
				_menuHeight();
				if($(_w).width()>menuBreakPoint) {
					menu.hide();
					menuIc.html('<i class="fa fa-bars"></i>');
					$('.top-bar-2').fadeOut('fast');
				}
			});
		}
		, _topMenu2 = function() {

            //add menu no comeco do body
            var topBar2 = $('.top-bar-2');
            var topBar2Html = $('<div>').append(topBar2.clone()).html();
            topBar2.remove();
            $('body').prepend(topBar2Html);

            topMenu2 = function () {

                var varscrolltop = $(_w).scrollTop();
                //mostrar/esconder menu scrolltop
                if ($(_w).width() > menuBreakPoint) {

                    if (varscrolltop >= 70) {
                        $('.top-bar-2 ').fadeIn('fast');
                    }
                    if (varscrolltop < 70) {
                        $('.top-bar-2 ').fadeOut('fast');
                    }
                }
            }
            topMenu2();

		}
		, _topMenu4 = function() {
			var el = $('.top-bar-4 .col-c .hover-menu');

			$(el).hover( function(){
				var index = $(this).index();
				$(el).removeClass('active');
				$(this).addClass('active');

				$('.top-bar-4 .col-d .submenu').removeClass('active');
				$('.top-bar-4 .col-d .submenu:eq('+index+')').addClass('active');

			})
		}, _validationTopSearch = function() {
         $('.top-search').submit(function(e){

            if($('.twitter-typeahead .tt-input').val().length >= 3 ){
                return;
            }

            e.preventDefault();

         })
        }, _FiltroToggle = function() {

            $(_w).resize(function() {
                if ( $(_w).width() > 990) {
                    $(".aside-filtro form, .aside-biblioteca").show();
                }
            });


            $(document).on('click', '.aside-filtro h2', function () {
                if ( $(_w).width() <= 990 ) {

                    if( $(this).hasClass('active') ){
                        $(this).removeClass('active').next().fadeOut();
                        $('.aside-biblioteca').fadeOut()
                    } else {
                        $(this).addClass('active').next().fadeIn();
                        $('.aside-biblioteca').fadeIn()
                    }

                }
            });




        }
        , _onScroll = function() {

            $(_d).scroll(function () {
                topMenu2();
            });

		}
		, _topMenu3 = function() {

			var topBar3 = $('.top-bar-3, .top-bar-3-bg');

			//click hamburguer
			$(_d).on('click','.hamburguer-02', function(e){
				e.stopPropagation();
				topBar3.fadeIn();

			});


			$(_d).on('click','.top-bar-3 .x',function(){
				topBar3.fadeOut();
			});

			$(_d).on('click','.top-bar-3 .container',function(e){
				e.stopPropagation();
			});

            $(_d).on('click', '.top-search-bt', function(){
                $(this).hide().parent().find('.top-search').fadeIn();
                $('.top-bar-4').fadeOut('fast');
                $('.icon-menu-4').removeClass('casa-color-bg-dark');
            });
            $(_d).on('click', '.icon-menu-4', function(){
                $('.top-search-bt').fadeIn('fast');
                $('.top-search').hide();

                $(this).toggleClass('casa-color-bg-dark');
                $('.top-bar-4').fadeToggle('fast');
            });
            $(_d).on('click', '.top-bar-4', function(){
                $('.top-search-bt').fadeIn('fast');
                $('.top-search').hide();

                $('.top-bar-4').fadeToggle('fast');
            });
            $('.menu-principal nav > ul > li').hover(function(){
				$(this).find('ul').stop().fadeIn('fast');
			},function(){
				$(this).find('ul').stop().fadeOut('fast');
			})



		}
		,  _btsFonts = function() {

			var elementsChange = $('.materia p, .eventos-interna p');

			if($('body').find(elementsChange).length != 0){

                if ( elementsChange.length ) {
                    var defaultSizeFont = Number($(elementsChange).css('font-size').split('p')[0]),
                        defaultSizeLine = Number($(elementsChange).css('line-height').split('p')[0]);
                }

                if ( $('h1').length ) {
                    var defaultSizeFontH1 = Number( $('h1').css('font-size').split('p')[0] ),
					    defaultSizeLineH1 = Number( $('h1').css('line-height').split('p')[0] );
                }



				var materiaP = $('p, p *, h3, h4, .gtm-materia-social, .gtm-materia-social *, .eventos-interna p, .eventos-interna p *'),
					materiaPA = $('.materia p, .eventos-interna p'),
					materiaH1 = $("h1");


				//default
				$('.bts-fonts > span.none').click(function () {

					change($(this),defaultSizeFont,defaultSizeLine, defaultSizeFontH1, defaultSizeLineH1);

				});
				//increase
				$('.bts-fonts > span.min').click(function () {

					var activeSizeFont = Number( materiaPA.css('font-size').split('p')[0] ),
						newSizeFont = activeSizeFont - 5,
						activeSizeLine = Number( materiaPA.css('line-height').split('p')[0] ),
						newSizeLine = activeSizeLine - 5,

						activeSizeFontH1 = Number( materiaH1.css('font-size').split('p')[0] ),
						newSizeFontH1 = activeSizeFontH1 - 5,
						activeSizeLineH1 = Number( materiaH1.css('line-height').split('p')[0] ),
						newSizeLineH1 = activeSizeLineH1 - 5;

					change($(this),newSizeFont,newSizeLine,newSizeFontH1,newSizeLineH1);

				});
				//decrease
				$('.bts-fonts > span.max').click(function () {

					var activeSizeFont = Number( materiaPA.css('font-size').split('p')[0] ),
						newSizeFont = activeSizeFont + 5,
						activeSizeLine = Number( materiaPA.css('line-height').split('p')[0] ),
						newSizeLine = activeSizeLine + 5,

                        activeSizeFontH1 = Number( materiaH1.css('font-size').split('p')[0] ),
						newSizeFontH1 = activeSizeFontH1 + 5,
						activeSizeLineH1 = Number( materiaH1.css('line-height').split('p')[0] ),
						newSizeLineH1 = activeSizeLineH1 + 5;

					change($(this),newSizeFont,newSizeLine,newSizeFontH1,newSizeLineH1);
				});

				var change = function(t, newSizeFont, newSizeLine,newSizeFontH1){
					t.parent().parent().parent().find(materiaP).animate({
						fontSize: newSizeFont+'px',
						lineHeight: newSizeLine+'px'
					});
                    $('.tab-content p, .tab-content p *, .downloads *').animate({//custom plugins
						fontSize: newSizeFont+'px',
						lineHeight: newSizeLine+'px'
					});


					t.parent().parent().parent().find(materiaH1).animate({
						fontSize: newSizeFontH1+'px',
						lineHeight: newSizeFontH1+'px'
					});


				}

			}
		}
		,  _clearFilters = function() {
			$('.limpar-filtros').click(function () {
				$('.aside-filtro form input[type="text"], .aside-filtro form input[type="checkbox"]').val('');
				$('.aside-filtro form input[type="checkbox"]').prop('checked',false);
				$('.aside-filtro form select').prop('selectedIndex',0);
			})
		}
		,  _tabsHover = function() {
			$('.tabs header ul li').not('.active').hover(function(){
				$(this).addClass('on')
			}, function(){
				$(this).removeClass('on')
			})
		}
		,  _anchor = function() {
			// console.log('anchor init')
			$(document).on('click', 'a[href^="#"]', function (e) {
                // console.log('anchor click')
				var target = this.hash;
				if (target === $('.main') || target === "#menu" || target === $('#main')) {
					console.log(target);
					$('html, body').animate({scrollTop:$(target).position().top}, 'slow');
					$(target).focus();
				}else{
					e.preventDefault();
					$(target).focus();
					if ($(this).parent().attr("role") == "presentation"){
						target = "#topo-estado-abas";
						myPushState = function () {
							// window.history.pushState(null,null, target)
						};
						var $target = $(target).offset();
						var alturaMenus = ($('.top-bar').height() + $('.top-bar-2').height()) * 2;
					}else{
						myPushState = function () {
							window.history.pushState(null,null, target)
						};
						var $target = $(target).offset();
						var alturaMenus = $('.top-bar').height() + $('.top-bar-2').height()
					}
					if(target === "#topo") {
						document.body.scrollTop = 0;
						document.documentElement.scrollTop = 0;
					  }
					if($target) {
						$('html, body').stop().animate({
							'scrollTop': $target.top - alturaMenus
						},  900, 'swing', myPushState);
					}
				}
			});

			//carregar mais PADRAO!!!!
			$(document).ready(function(){

				$(document).on('click', '.next-page-action', function(){
					var counte = 0;

					switch($(this).attr('id')){

                        case 'nextPage6':
							totalEl = 6;
							tab = '#list';
							break;
						case 'nextPage15':
							totalEl = 15;
							tab = '#list';
							break;
						default:
							totalEl = 7;
							tab = '#list';
							break;
					}

					$(this).parents('.tab-content').find(tab+' .item-action:hidden').each(function(e){
						counte += 1;
						//console.log('counte',counte);
						if (e <= totalEl - 1 ){
							$(this).fadeIn()
						}
					});

					if(counte <= totalEl ){
						$(this).parents('.tab-pane').find('.next-page-action').slideToggle()
					}
				})

			})

		}
		,  _carousel = function() {

            $('#myCarousel, div[id^="carousel-example-generic-"], div.carousel, #carousel-4,#slider-thumbs').on("touchstart", function(event){
                    var xClick = event.originalEvent.touches[0].pageX;
                $(this).one("touchmove", function(event){
                    var xMove = event.originalEvent.touches[0].pageX;
                    if( Math.floor(xClick - xMove) > 5 ){
                        $(this).carousel('next');
                    }
                    else if( Math.floor(xClick - xMove) < -5 ){
                        $(this).carousel('prev');
                    }
                });
                $('#myCarousel, div[id^="carousel-example-generic-"], div.carousel, #carousel-4,#slider-thumbs').on("touchend", function(){
                        $(this).off("touchmove");
                });
            });

            $('.left.carousel-control').attr('style','display: none !important');

			var showHideBtsControl = function (t) {
				//fechar
                  var $this = t;
                  $this.find('.carousel-control').show();

                  if($this.find('.carousel-inner .item:first').hasClass('active')) {
                    $this.find('.left.carousel-control').attr('style','display: none !important');
                  } else if($this.find('.carousel-inner .item:last').hasClass('active')) {
                    $this.find('.right.carousel-control').attr('style','display: none !important');
                  }
			};

			//carousel normal
            $('#myCarousel, div[id^="carousel-example-generic-"], div.carousel, #carousel-4 ').carousel({
                interval: false,
                wrap: false
            }).on('slid.bs.carousel', '', function() {
                showHideBtsControl($(this));
            });

			//carousel com thumbs
            $('div[id^="slider-thumbs-"]').carousel({
                interval: false,
                wrap: false
            }).on('slid.bs.carousel', '', function() {

                showHideBtsControl($(this));

                //passar thumbs quando passar slider
				var id = $('#slider-thumbs-main .item.active').attr('data-slide-number'),
					id = parseInt(id);

				$('[id^=carousel-selector-]').removeClass('selected');
				$('[id=carousel-selector-'+id+']').addClass('selected');

            });

			// handles the carousel thumbnails
			$('[id^=carousel-selector-]').click( function(){
			  var id_selector = $(this).attr("id");
			  var id = id_selector.substr(id_selector.lastIndexOf("-")+1);
			  id = parseInt(id);
			  $('#slider-thumbs-main').carousel(id);
			  $('[id^=carousel-selector-]').removeClass('selected');
			  $(this).addClass('selected');
			});

			//carousel,  ADD botão de ampliar no slider, pois as legendas e imagens são adicionadas pelo Filer e não temos controle do HTML
			$('#slider-thumbs-main > div .item').each(function () {
				var imgSrc = $(this).find('img').attr('src');
				$(this).find('.filer_image_info').append(''+
					'<a rel="album_fotos" href="'+imgSrc+'" data-url="/cni/bm/albuns/foto/download/85997" data-userok="S" class="element fotos-a" title="85997">'+
						'<i class="fr fa fa-arrows-alt b-24"></i>'+
					'</a>')
			})


		}
		,  _asideToggle = function() {

			//estatistcas
			$(document).ready(function(){
				if(!$('.tema-lista').length){
					$('.busta-tema .sub-title').click();
				}
			});

			$(document).on('click', '.aside-menu-2 .sub-title' ,function(){
				if(!($(this).parent().hasClass('sem-seta') || $(this).hasClass('sem-toggle'))){
					$(this).parent().find('li:nth-child(n+2)').slideToggle();
					$(this).toggleClass('sub-title-active');
				}
			});

            $(document).on('click', '.title-collapse' ,function(){
				$(this).next().slideToggle();
				$(this).toggleClass('sub-title-active');

			});

			// toggle eventos
			$(document).on('click', '.accordion-item' ,function(){
				$(this).next().slideToggle();
				$(this).find('.toggle .fa').toggleClass('fa-minus');
			});


		},  _setaToggle = function() {
			$(document).on('click', '.seta-toggle' ,function(){
				$(this).parent().find('.seta-toggle-cont:nth-child(n+2)').slideToggle();
				$(this).toggleClass('seta-toggle-active');
			});

			$(document).on('click', '#mapa-buscar-resultado .click' ,function(){
				$(this).parent().find('.more').slideToggle();
				$(this).parent().toggleClass('seta-toggle-active');
				if($(this).parent().hasClass('seta-toggle-active')) {
					$(this).parent().find('.fechar strong').text('FECHAR');
				} else {
					$(this).parent().find('.fechar strong').text('ABRIR');
				}
			});


		},  _footerCollapse = function() {
            $(document).on('click', '.footer-top .title' ,function(){

                //if( $(_w).width <= 767 ){
                    if(!$(this).hasClass('active')){
                        $(this).next().slideToggle();
                        $(this).addClass('active');
                        $(this).find('.icone').text('-');
                    } else {
                        $(this).next().slideToggle();
                        $(this).removeClass('active');
                        $(this).find('.icone').text('+');
                    }

                //}

            })
        }
		,  _customCollapse = function() {
			$(document).on('click', '.collapse-custom .panel-heading' ,function(){
				if($(this).hasClass('collapsed')){
					$(this).find('.after').text('+');
				} else {
					$(this).find('.after').text('-');
				}
			});

			$('#legado-cms-3mw .accordion .p-title .icon').addClass('after').text('+');
			$('#legado-cms-3mw .downloads > div a .icon, #legado-cms-3mw .download-arquivo > div li .separador-download-arquivo ').addClass('gradient');

			$(document).on('click', '.collapse-noticia .panel-heading, #legado-cms-3mw .accordion .p-title' ,function(e){
				e.preventDefault();

				if(!$(this).hasClass('active')){

                    $(this).parent().parent().find('.active').next().slideToggle('fast');
					$(this).parent().parent().find('.active .after').text('+');
                    $(this).parent().parent().find('.active').removeClass('active');

                    $(this).next().slideToggle('fast');
					$(this).find('.after').text('-');
                    $(this).toggleClass('active');

				} else {

                    $(this).next().slideToggle('fast');
					$(this).find('.after').text('+');
                    $(this).toggleClass('active');

				}
			});


		}
		,  _customCheckbox = function() {
			$(document).on('click', '.custom-checkbox', function(){
                if( !$(this).hasClass('active') ){
                    $(this).find('input').prop('checked','checked');
                } else {
                    $(this).find('input').prop('checked','');
                }

				$(this).toggleClass('active');
			})
		}
		,  _bm = function() {

            // hover botoes "fotos"
			$('#banco-midias .main header .bar-top > div > ul ').hover(function(){
				$(this).find('ul').stop().fadeIn('fast');
			},function(){
				$(this).find('ul').stop().fadeOut('fast');
			});

            //Botão calendário
			var calendar = $('.search .calendar'),
				calendarRadio = $('.search .calendar input[type="radio"]'),
				calendarValDe = $('.search input[type="date"]:nth-child(1)').val(),
				calendarValAte = $('.search input[type="date"]:nth-child(2)').val();

            var changeRadio = function(){

                var radioActive = calendar.find('input:checked').attr('value');

                if (radioActive ===  'periodo') {
                    calendar.animate({
					    width: '454'
                    },600, function(){
                        $(this).find('.ate, .data_fim').fadeIn('fast');
                    });
                } else {
                    calendar.find('.ate, .data_fim').fadeOut('fast',function(){
                        calendar.animate({
					        width: '230'
                        },600);
                    });
                }
            };
            changeRadio();

			//open modal
			$(_d).on('click','#banco-midias .main .search .fa-calendar',function(){
				$('#banco-midias .main .search .calendar').stop().fadeToggle('fast');
			});
			$(_d).on('click','#banco-midias .btn',function() {
				$('.calendar').stop().fadeOut('fast');
			});
			$(_d).on('click','#banco-midias .btn-close',function(){
				$('.search input[type="date"]:nth-child(1)').val(calendarValDe);
				$('.search input[type="date"]:nth-child(2)').val(calendarValAte);
			});

            //selecionar radio dentro do calendário
			$(document).on('change', calendarRadio ,function(){
                changeRadio($(this));
			});

		}
		,	_canal01 = function() {
			$(_d).on('click','.collapse-institucional a', function(){

				if ( !$(this).hasClass('active') ) {

					$(this).parent().parent().find('.active').next().slideToggle();
					$(this).parent().parent().find('.active .fa').removeClass('fa-minus-square').addClass('fa-plus-square');
                    $(this).parent().parent().find('.active').removeClass('active');

					$(this).toggleClass('active');
					$(this).next().slideToggle();
					$(this).find('.fa').toggleClass('fa-plus-square').toggleClass('fa-minus-square');

				} else {
                    $(this).toggleClass('active');
					$(this).next().slideToggle();
					$(this).find('.fa').toggleClass('fa-plus-square').toggleClass('fa-minus-square');
                }

			});
			$(_d).on('click','.canal-01-videos .nav li',function(){
				if( !$(this).hasClass('gradient') ){
					var indexEl = $(this).index() + 1;

					$(this).parents('.canal-01-videos').find('.gradient').removeClass('gradient');
					$(this).addClass('gradient');

					$(this).parents('.canal-01-videos').find('.nav-cont > div:visible').hide();
					$(this).parents('.canal-01-videos').find('.nav-cont > div:nth-child('+indexEl+')').fadeIn();
				}
			});
			$(_d).on('click','.bt-toggle', function(){
				$('.busca-simples').toggle();
				$('.busca-avancada').toggle();
			})

		}
		,	_canal03 = function() {
			$(document).ready(function () {

				$(document).on('change','.canal-03-buscar-propostas .select-style select', function () {
					$('#coex_form').submit();
				});

				// mostrar/esconder 'saiba mais'
				$('.canal-03-casos-destaque-02 .news').each(function(){
					if( $(this).find('a').length <= 6 ){
					 	$(this).next('.next-page').hide();
					} else {
						$(this).find('a').each(function(){
							if($(this).index() >= 6){
								$(this).hide();
							}
						})
					}
				});

				// mostrar/esconder 'saiba mais' na página RESULTADOS
				var el2 = $('.collapse-noticia-03 .item-action');
				if( el2.length <= 6 ){
					el2.parents('.tab-pane').find('.next-page').hide();
				}

			});
		}
		,	_tooltip = function() {
			$(document).on('click', '.tooltip-2' ,function(){
                if($(this).hasClass('active')){
					$(this).removeClass('active');
					$(this).find('.txt').text('?');
					$(this).prev().find('.padding').toggle();
				} else {
					$(this).addClass('active');
					$(this).find('.txt').text('x');
					$(this).prev().find('.padding').toggle();
				}

			})
		}
		,	_paginaSites = function() {

            var filtro = { instituicao: 'todos', iniciativas: 'todos' },
                todos = 'todos',
                elListaDestaque = $('.lista-destaque');

            function contagem(){
                //atualizar contagem de exibicao
                var lenght = $('.tab-pane').find('.each:visible').length,
                    txtExibindo = $('.exibindo:first-child').text().split(' ');
                    txtExibindo[1] = lenght;
                    //console.log(lenght)
                var txtExibindo = txtExibindo.toString().replace(/[,]/g,' ');

                $('.exibindo').text(txtExibindo);
            }
            contagem();

			$(_d).ready(function () {
				$(_d).on('click', '#nextPage15', function () {
					//atualizar contagem de exibicao
					var txtExibindo = $('#list').find('.item-action:visible').length;
					//console.log(txtExibindo)
					$('.exibindo').text(txtExibindo);
				})
			});
            var casa = $('.instituicao select option:checked').text();
            if (casa.toLowerCase() !== todos && casa !== "") {
                $("#lista-sites .each").not("[data-instituicao*="+casa+"]").hide();
                contagem();
            }
            // esconder itens acima de 9 & atualizar contagem
            if ($('.carregar-mais').length > 0) {
                $('#lista-sites .each:visible').each(function(index){
                    var elThis = $(this);
                    if(index > 8){
                        elThis.hide();
                    }
                });
                contagem()
            }

            //carregar mais
            $(document).on('click','.lista-sites .carregar-mais',function(){
                $(this).hide();
                // $('#lista-sites .each').fadeIn();
                $('.instituicao .chzn-results li.result-selected').click();
                contagem();
            });

            //click filtro
			$(document).on('click','.chzn-results li',function(){
                $('.lista-sites .carregar-mais').hide();

				var elParent = $(this).parents('.each'),
                    el = elParent.find('.page-select').val();


                    //alimenta objeto
                    if(elParent.hasClass('instituicao')){
                        filtro.instituicao = el;
                    }else{
                        filtro.iniciativas = el;
                    }

                    //o que procurar
                    var procurarInstituicao = ( (filtro.instituicao !== todos) ),
                        procurarIniciativas  = ( (filtro.iniciativas !== todos) );


                    //procurar itens
                    function procurar(procurarInstituicao, procurarIniciativas){

                        if (!procurarInstituicao && !procurarIniciativas){
                            elListaDestaque.fadeIn('fast');
                            $('.lista-sites .each').fadeIn('fast');

                            contagem()

                        } else {

                            $('#lista-sites .each').each(function(){
                                var dataInstituicao = $(this).attr('data-instituicao'),
                                    dataIniciativas = $(this).attr('data-iniciativas');

                                elListaDestaque.fadeOut('fast');

                                var contem = function(instituicao, filtro) {
                                  return instituicao.indexOf(filtro) !== -1;
                                }

                                if (procurarInstituicao && procurarIniciativas){
                                    if(contem(dataInstituicao, filtro.instituicao) && dataIniciativas == filtro.iniciativas ) {
                                        $(this).fadeIn('fast');
                                    } else {
                                        $(this).fadeOut('fast');
                                    }
                                } else if (procurarInstituicao){
                                    if(contem(dataInstituicao, filtro.instituicao) ) {
                                        $(this).fadeIn('fast');
                                    } else {
                                        $(this).fadeOut('fast');
                                    }
                                } else {
                                    if(dataIniciativas == filtro.iniciativas) {
                                        $(this).fadeIn('fast');
                                    } else {
                                        $(this).fadeOut('fast');
                                    }
                                }

                            }).promise().done(function(){//quando terminar o each, executar contagem()
                                contagem()
                            });
                        }

                    }
                    procurar(procurarInstituicao, procurarIniciativas);
			});
            // if ($('.instituicao .chzn-select option').length > 0) {
            //     var timer = setInterval(function() {
            //        if ($('.instituicao .chzn-results li.result-selected').length > 0) {
            //            $('.instituicao .chzn-results li.result-selected').click()
            //            contagem()
            //            clearInterval(timer);
            //        }
            //     }, 200);
            // }
            $(document).on('click','.nav-tabs a, .tabs-noticia-lateral .nav a',function (e) {
                window.location.hash = this.hash;
            });
            //vem aberto de acordo com hash da aba
            if (location.hash !== '') $('a[href="' + location.hash + '"]').tab('show');
            return $('a[data-toggle="tab"]').on('shown', function(e) {
                return location.hash = $(e.target).attr('href').substr(1);
            });


		}, _tabCustom = function() {

            $(document).on('click','.tabs .tab-nav li', function () {
                $(this).parents('.tab-nav').find('li').hasClass('gradient') ? activeClass = 'gradient' : activeClass = 'active';
                if( !$(this).hasClass(activeClass) ){
                    var indexEl = $(this).index() + 1;

                    $(this).parents('.tab-nav').find('.'+activeClass).removeClass(activeClass)
                    $(this).addClass(activeClass);

                    $(this).parents('.tabs').find('.tab-cont > div:visible').hide();
                    $(this).parents('.tabs').find('.tab-cont > div:nth-child('+indexEl+')').fadeIn();
                }
            });

            //$(document).ready(function () {
                if ($('.tabs .nav-tabs li').length > 0) {
                    //load
                    $('.tabs .nav-tabs li a.gradient').removeClass('gradient');
                    $('.tabs .nav-tabs li.active').children().addClass('gradient');
                }
            //})

        }, _selectAllCheckbox = function() {
            $(document).on('click','.select-all-checkbox', function () {
                $(this).parents('.input-lista').find('input[type="checkbox"]').each(function(){
                    if( !$(this).hasClass('select-all-checkbox') ){
                        $(this).click();
                    }
                });

            })
        }, _customInputFile = function() {

			var formFiles = {},
                sizes = { "2M": 2097152, "50M": 52428800 };

			$(".input-file-custom input[type='file']").on("change", function () {

				if ( this.files[0] ) {
					formFiles[this.name] = uploadValido(getFileInfo(this, this.files[0]));
					$(this).parents('.input-file-custom').find('.path').text(formFiles[this.name].name);
				} else {
					formFiles[this.name] = false;
					$(this).parents('.input-file-custom').find('.path').text("Selecione um arquivo...");
				}
				$(this).valid();
			});

			function uploadValido(fileInfo) {
                fileInfo.validType = fileInfo.validTypes.indexOf(fileInfo.type) >= 0;
                fileInfo.validSize = fileInfo.maxSize === false || ! fileInfo.validType || fileInfo.size <= fileInfo.maxSize;
            	return fileInfo;
            }

			function getFileInfo(el, file) {
				return {
					el: el,
					id: el.id,
					type: file.type,
					size: file.size,
					name: file.name,
					maxSize: 999999,
					validTypes: el.dataset["validtypes"] ? el.dataset["validtypes"].split(",") : [],
					validType: null,
					validSize: null
				};
			}

			//sem autenticacao
			var inputFileCustom = '.input-file-custom-2',
                inputFileCustomInput = ".input-file-custom-2 input[type='file']";

			var inputFileCustomText = $('.input-file-custom-2 .path').text() || 'Selecione um arquivo...';

			$(inputFileCustomInput).on("change", function () {

				if (this.files[0]) {
					$(this).parents(inputFileCustom).find('.path').text(this.files[0].name);
				} else {
					$(this).removeClass('active').parents(inputFileCustom).find('.path').text(inputFileCustomText);
				}

			});

			$(inputFileCustomInput).on('dragover', function () {
				$(this).parents(inputFileCustom).addClass('active')
			});
			$(inputFileCustomInput).on('dragleave', function () {
				$(this).parents(inputFileCustom).removeClass('active')
			});
			$(inputFileCustomInput).on('drop',function(){
				$(this).parents(inputFileCustom).removeClass('active')
			});

			$('.js_remover-anexo').on("click", function () {
				$(inputFileCustomInput).val(null).change();
			})



		}, _goBack = function() {
			$(document).on('click','.go-back',function (e) {
				e.preventDefault();
				window.history.back()
			})

		},  _carregarMaisSites = function() {

			$(window).load(function () {

				var btnCarregarMais = '.lista-sites .carregar-mais',
					$btnCarregarMais = $(btnCarregarMais);

				var currentCanal = document.location.pathname.split('/')[1],
                    str = 'pi-canal-carregar-mais' + currentCanal,
                    carregarMais = sessionStorage.getItem(str);

				if( $btnCarregarMais ){

					if ( carregarMais == 'true'){
						$btnCarregarMais.click();
					} else {
						sessionStorage.setItem(str ,'false');
					}

					$(document).on('click', btnCarregarMais , function () {
						sessionStorage.setItem(str ,'true');
					});

				}

			});

		}, _filtroTrabalhador = function() {

			var checkbox 	= $('.filtro-trabalhador .filtro input[type="checkbox"]');
			var elList 		= $('.box-trabalhador');
			var inputs 		= {
				educacao: true,
				qualidade_de_vida: true,
				tecnologia_e_inovacao: true
			};

			checkbox.change(function () {

				var categoriaFiltro = $(this).attr('data-categoria');
				var turn = ( $(this).is(':checked') ) ? true : false;
				//atualizar obj
				inputs[categoriaFiltro] = turn;
				//console.log(inputs);

				elList.each(function(){

					var thisDataEducacao 	= ( $(this).attr('data-categoria-educacao') == 'True');
					var thisDataQualidade 	= ( $(this).attr('data-categoria-qualidade') == 'True');
					var thisDataTecnologia 	= ( $(this).attr('data-categoria-tecnologia') == 'True');
					//console.log('thisDataEducacao', thisDataEducacao, 'thisDataQualidade', thisDataQualidade, 'thisDataTecnologia', thisDataTecnologia);

					if( inputs.educacao && thisDataEducacao || inputs.qualidade_de_vida && thisDataQualidade || inputs.tecnologia_e_inovacao && thisDataTecnologia ){
						$(this).show()
					} else {
                        if( inputs.educacao && inputs.qualidade_de_vida && inputs.tecnologia_e_inovacao ){//se todos os filtros tiverem ativos mostra todos, até os que não pertencem ao filtros.
                            $(this).show();
                        } else {
                            $(this).hide()
                        }

					}

				})

			})

		}, _infograficoDircomMapa = function() {
		    //resolvendo problema de altura do iframe
            $(document).ready(function () {

                var infografico = $('.infografico-mapa#info-dircom');


                if(infografico.length !== 0) {

                    function receiveMessage(event) {//iframe manda para nós dessa forma

                        var viewAtual = event.data.viewAtual || '';
                        var larguraWindow = $(window).width();

                        var mudarAltura = function (viewAtual) {

                            $('.infografico-mapa#info-dircom').each(function () {

                                var t = $(this);
                                var alturaInicial = $(this).attr('height');

                                if( viewAtual == 1 && t.is(':visible') ){
                                    t.height('4460px');
                                } else {
                                    t.height(alturaInicial);
                                }

                            })

                        };

                        if (larguraWindow >= 1200) {//apenas no desktop, em mobile é apenas lista
                            mudarAltura(viewAtual);
                        }

                    }

                    window.addEventListener("message", receiveMessage, false);
                }
            })
        },
			_init = function(){
			_openMenu();
			_menuHeight();
			_preventHover();
			_onResize();
			_topMenu2();
			_topMenu3();
			_onScroll();
			_tabsHover();
			_clearFilters();
			_anchor();
			_carousel();
			_asideToggle();
            _setaToggle();
			_topMenu4();
			_customCollapse();
			_customCheckbox();
			_tooltip();
			_bm();
			_paginaSites();
			_canal01();
			_canal03();
            _footerCollapse();
            _tabCustom();
            _selectAllCheckbox();
			_customInputFile();
            _FiltroToggle();
            _validationTopSearch();
			_goBack();
			_carregarMaisSites();
			_filtroTrabalhador();
			_infograficoDircomMapa();

			$(_d).ready(function(){
				_btsFonts();
			})

		}
		return {
			init: _init
		}
	}().init();

	_PortalIndustria.HomeCni = !function(){
		_init = function() {
			//var varscrolltop = $(window).scrollTop()

			$('.modal-email .add-ass').click(function(){
                if( $(this).hasClass('active')){
                    $(this).removeClass('active');
					$(this).html('<span class="before">+</span> <span class="txt">Adicionar mais assuntos</span>');

                    $(this).next().find('input').each(function(){
                        if( !$(this).prop('checked') ){
                            $(this).parents('li').fadeOut();
                            $(this).parent();
                        }
                    })

                } else {
                    $(this).addClass('active');
                    //$(this).html('<span class="before">-</span> Remover mais assuntos');
					$(this).html('<span class="before">-</span> <span class="txt">Adicionar mais assuntos</span>');

                    $(this).next().find('li').fadeIn();
                }

			})


		}
		return {
			init: _init
		}
	}().init();

	_PortalIndustria.NoticiasCni = !function(){
		_init = function() {
			//bootstrap
			$('#myTabs a').click(function (e) {
				e.preventDefault()
				$(this).tab('show')
			})

		}
		return {
			init: _init
		}
	}().init();




	//*******PLUGINS*******
	$.fn.mapaBuscar = function(urlAjax, jsonEstado, apiKey, callback) {
		var elLoading = $(".loading-circle");

		elLoading.show();

        //le o googlemaps apenas 1x
        if (typeof loadGoogleMaps == 'undefined'){
            loadGoogleMaps = $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD-DKH-XXUFcoiSFz2mFyPi2TJetIOVEl8&signed_in=true");
        } else {
            loadGoogleMaps = true;
        }

        $.when(loadGoogleMaps).then(function(){
            //inicializar o mapa apenas 1x
            if(typeof initMapVar == 'undefined'){
                markers = [];
                function initMap() {
                    map = new google.maps.Map(document.getElementById('map'), {});
                }
                initMap();
                initMapVar = false;
            }

            //pega do html, o html base
            var mapaResultadoBusca = $('#mapa-buscar-resultado');

            //pegar o htmlBase apenas uma vez, que é o html base para criar a lista
            if(typeof htmlBase == 'undefined') {
                htmlBase = mapaResultadoBusca.children().html();
            }

            //possui lista que fica fora do googlemaps??
            if (htmlBase) {
                var possuiLista = true
            } else {
                var possuiLista = false
            }

		    initAll(htmlBase);




            //Funcoes
            function setMarkers(places) {

                var resultadoBusca = $('#resultado-busca');

                //mostrar/show lista em baixo do mapa
                if( !resultadoBusca.is(':visible')){
                    resultadoBusca.fadeIn('fast');
                }

				var bounds = new google.maps.LatLngBounds();

				var infowindow = new google.maps.InfoWindow();
                for (var i = 0; i < places.length; i++) {
                    var place = places[i];
                    if (parseFloat(place.latitude) != 0 && parseFloat(place.longitude) != 0){
                        var marker = new google.maps.Marker({
                            animation: google.maps.Animation.DROP,
                            map: map,
                            position: {lat: Number(place.latitude), lng: Number(place.longitude)}

                        });

                        markers.push(marker);
                    }else{
                        console.log(place);
                    }

                    function telefoneUnidade(place) {
						if (place.hasOwnProperty('telefone')){
							return place['telefone']
						}
						if (place.hasOwnProperty('telefones')){
							return place['telefones'].join(', ');
						}
						return '';
                    }
                    telefones = place.telefone;
                    var content = "" +
                        "<div style='font-size:18px;line-height:20px;'>" +
                            "<strong>" + place.cidade.nome + "</strong><br>" +
							"<strong>Endereço:</strong> " + place.endereco + "<br>" +
							"<strong>Bairro: </strong>" + place.bairro + "<br>" +
							"<strong>CEP: </strong>" + place.cep + "<br>" +
							"<strong>Telefone(s): </strong>" + place.telefone + "<br>" +
                        "</div>" +
                        "<br/>";

                    bounds.extend(marker.position);

                    google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
                        return function() {
							if (infowindow) { infowindow.close();}
                        	infowindow.setContent(content);
                           	infowindow.open(map,marker);
                        };
                    })(marker,content,infowindow));


                }

				map.fitBounds(bounds);

            }

            function clearMarkers() {
                for (var i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }
                markers = [];
            }


            function initAll(htmlBase){

                clearMarkers();

                $.ajax({
					url: urlAjax,
    				type: 'GET',
					crossDomain:true,
					success: function( data ) {

						var places = data.results;

						$(".error-unidades").hide();
                        errorEl = $('.error-unidades');
						naoInformado = 'Não informado';

                        if (data.count) {

							errorEl.hide();
							$("#map").show();
							$(".container h3 strong").show();
							elLoading.hide();


                            //colocar titulo com o nome da cidade
							if( $('.unidades-proximas-epead').length ){
								if (typeof places[0].cidade !== 'undefined') {
                                    $('.unidades-proximas-epead .dc strong').text(places[0].cidade.nome + ' (' + places[0].cidade.estado.sigla + ')');
                                } else {
									$('.unidades-proximas-epead .dc strong').text(places[0].cidade.nome + ' (' + places[0].cidade.estado.sigla + ')');
								}
							}

                            //pega a latitude e longitude que esta em um atributo do option
                            var select = $('.mapa-buscar option:selected'),
                                tituloBusca = $('#titulo-buscando'),
                                htmlFinal = [];


                            //inicializa googlemaps
                            $("#map").show(function () {
                                map = new google.maps.Map(document.getElementById('map'), {});
                            });



                            //Constroi  a lista que está fora do maps
                            if (possuiLista) {

                                //add itens na lista html que está fora do google maps
                                for (var i = 0; i < places.length; i++) {

                                    var place = places[i];

									var placeCasa, placeCidade, placeCidadesId, placeEstadoId = '';

									if (typeof place.cidade.estado.nome !== 'undefinDed') {
										placeCasa = place.cidade.estado.nome;
									}
									if (typeof place.cidade.nome !== 'undefined') {
										placeCidade 	= place.cidade.nome;
										placeCidadesId 	= place.cidade.nome;
										placeEstadoId 	= place.cidade.estado.sigla;
                                    }

                                    var placeTitulo = place.nome,
										placeSlug = place.slug,
										placeCodigo 	= place.codigo,
										placeBairro 	= place.bairro,
                                        placeEndereco 	= "<p><strong>Endereço: </strong> " + place.endereco,

										placeTelefone 	= ( (place.dados_cadastrais.fone !== naoInformado) ? "<p><strong>Tel:</strong> " + place.dados_cadastrais.fone + "</p>" : "" ),
                                        placeSite 		= ( (place.dados_cadastrais.link !== naoInformado) ? "<p><strong>Site:</strong> <a href='" + place.dados_cadastrais.link + "'>" + place.dados_cadastrais.link + "</a></p>" : "" ),
                                        placeEmail 		= ( (place.dados_cadastrais.email!== naoInformado) ? "<p><strong>Email:</strong> <a href='mailto:" + place.dados_cadastrais.email + "'>" + place.dados_cadastrais.email + "</a></p>" : "" ),
                                        placeCEP 		= ( (place.dados_cadastrais.zipcode !== naoInformado) ? "<p><strong>CEP:</strong> " + place.dados_cadastrais.zipcode + "</p>" : "" ),
                                        //placeCidadesId  = ( (place['cep'] ) ? "<p><strong>CEP:</strong> " + place['cep'] + "</p>" : "" ),

										placeComochegar = 'http://maps.google.com/?q=' + place.latitude + ',' + place.longitude;



									if (placeBairro != naoInformado){
										placeEndereco = placeEndereco + ", " + placeBairro;
									}
									if (placeCidade != naoInformado){
										placeEndereco = placeEndereco + " - " + placeCidade;
									}

                                    var htmlBaseThis = htmlBase.replace('PLACES-CASA', placeCasa)
                                        .replace('PLACES-TITULO', placeTitulo)
										.replace('PLACES-ENDERECO', placeEndereco)
										.replace('PLACES-CEP', placeCEP)
                                        .replace('PLACES-COMOCHEGAR', placeComochegar)
										.replace('PLACES-TELEFONE', placeTelefone)
										.replace('PLACES-SITE', placeSite)
										.replace('PLACES-EMAIL', placeEmail)
										.replace('PLACES-SLUG', placeSlug)
										.replace('PLACES-ID-CIDADE', placeCidadesId)
										.replace('PLACES-ID-ESTADO', placeEstadoId)
										.replace('PLACES-CODIGO', placeCodigo);

									htmlFinal.push(htmlBaseThis);

                                }

                                setMarkers(places);
                                mapaResultadoBusca.html(htmlFinal);


                                //titulo
                                tituloBuscando = [];
                                select.each(function () {
                                    tituloBuscando.push($(this).text());
                                });
                                if (tituloBuscando[1] != '') {
                                    tituloBusca.text(tituloBuscando[0] + ' (' + tituloBuscando[1] + ')');
                                } else {
                                    tituloBusca.text(tituloBuscando[0]);
                                }





                            } else {
                                setMarkers(places);
                            }

                            $('#mapa-buscar-resultado').children().show();

							//fim
							elLoading.hide();
							//callback();



                        } else {
							$("#map").hide();
							elLoading.hide();
							$(".container h3 strong").hide();
							$(".error-unidades").show().text('Não existem unidades do SENAI neste município.');
                            mapaResultadoBusca.html('');
                            $('.unidades-proximas-epead .dc strong').text('Não existem unidades do SENAI neste município.');

                            $('#mapa-buscar-resultado').children().hide();

                        }

					}




                });


            }

        });



	};

	$.fn.jsonCidadesEstados = function(options) {

 	 	 var settings = $.extend({
           	el: this,
			titleName: 'UF',
			typeName: 'sigla',
			urlJson: '../portaldaindustria/static/js/estados_cidades.json'
         }, options );

		return this.each(function() {

			$.getJSON(settings.urlJson, function (data) {

				var options = '<option value="">'+settings.titleName+'</option>';

				$.each(data, function (key, val) {
					options += '<option value="' + val.sigla + '">' + val[settings.typeName] + '</option>';
				});

				settings.el.html(options);
				settings.el.change(function () {

					var options_cidades = '';
					var str = "";

					settings.el.find("option:selected").each(function () {
						str += $(this).text();
					});

					$.each(data, function (key, val) {
						if(val[settings.typeName] == str) {
						  $.each(val.cidades, function (key_city, val_city) {
							options_cidades += '<option value="' + val_city + '">' + val_city + '</option>';
						  });
						}
					});
					settings.el.parents('form').find(".cidades-json").html(options_cidades);

				}).change();

			})
		});
	};

	$.fn.filtroPadrao = function(options) {
		 var settings = $.extend({
			htmlBase: '',//base de html para cada elemento do laco
			htmlBaseVar: '',//variaveis de cada elemento do laco
			urlAjax: '',//url onde vai ser puxado o "saiba mais"
            contBusca:'',//onde o laço vai ser renderizado
            item:''//cada elemento do laço
         }, options );


		var  formulario = $(this),
			 buscaCont = $('.'+settings.contBusca+''),
			 btCarregarMais = $('.carregar-mais'),
             qntItensTotal = Number( $('input[name="contador_filtro"]').attr('value') ),
             qntItens = $('.qnt-itens');

		formulario.submit(function(event) {

			//zerar qnt de itens no input hidden, verificar se vem do evento.submit ou do botão submit
			if(typeof event['originalEvent'] != 'undefined'){//quando for botão "buscar"
				$('input[name="nro_objetos"]').val(0);
			} else {//quando for botão carregar mais
				getItens( $(this) );
				event.preventDefault();
			}

		});

		$(document).on('click', '.carregar-mais', function() {
			//add qnt de itens no input hidden
			var qntEl = buscaCont.find('.'+settings.item).length;
			$('input[name="nro_objetos"]').val(qntEl);

			formulario.submit();

		});

		var getItens = function (form){

				var url = settings.urlAjax,
					htmlFinal = [];

				$.ajax({
						type: "GET",
						url: url,
						dataType: "json",
						data: $( form ).serialize(),
						success: function (data) {
                            //console.log(data);
							if (!data[0]['error']) {
                                buscaCont.find('tr.erro').hide();

								for (var i = 0; i < data.length; i++) {

                                    var item = data[i],
                                        htmlBaseEach = settings.htmlBase;

                                    for (var j = 0; j < settings.htmlBaseVar.length; j++) {
                                        var itemVar = settings.htmlBaseVar[j];
                                        htmlBaseEach = htmlBaseEach.replace(itemVar, item[itemVar]) ;
                                    }

                                    htmlFinal.push( htmlBaseEach );

								}

								buscaCont.append(htmlFinal);

                                var qntEl = buscaCont.find('.'+settings.item).length

								if(qntEl >= qntItensTotal){
									btCarregarMais.fadeOut()
								} else {
									btCarregarMais.fadeIn()
								}

							} else {
								buscaCont.html('<tr class="erro"><td colspan="6">Não possui itens com esse filtro</td>');
							}

                            //atualizar o texto que mostra a quantidade de itens que estão sendo exibidos
                            var qntItensVal = buscaCont.children().length;
                            qntItens.html(qntItensVal)
						}
			   });
		};
	};

    $.fn.abasInstitucional = function(options) {
        var settings = $.extend({
            urlPluginId: '',//ID

            urlNoticias: '',
            qntNoticias: 8,
            totalNoticias:'',

            urlPublicacoes: '',
            qntPublicacoes: 10,
            totalPublicacoes:'',

            urlEstatisticas: '',
            qntEstatisticas: 10,
            totalEstatisticas:'',

            urlEventos: '',
            qntEventos: 6,
            totalEventos:'',

            urlSites: '',
            qntSites: 6,
            totalSites:''


        }, options);



        var nro_objetos = 0,
            nro_objetos_Noticias = 0,
            nro_objetos_Publicacoes = 0,
            nro_objetos_Estatisticas = 0,
            nro_objetos_Eventos = 0,
            nro_objetos_Sites = 0;

        //Regex
        var regexImg = new RegExp("src=\"(.*?)\""),
            regexImgBg = /url\((.*.)\)/g,
            regexImgHref = new RegExp("href=\"(.*?)\""),
            regexImgPublicacoes = new RegExp("href=\"(.*?)\""),
            regexTitulo = new RegExp("<p>(.*?)<\/p>"),
            regexH3 = new RegExp("<h3>(.*?)<\/h3>"),
            regexLocal = new RegExp("Local:<\/strong>(.*?)<\/p>"),
            regexData = new RegExp("Data:<\/strong>(.*?)<\/p>"),
            regexTituloSites = new RegExp("<\/i>(.*?)<\/a>");

        //variaveis para pegar os HTMLs padrão de cada aba

        var htmlNoticia = $('<div></div>').append($('.tab-pane#tab-1 > div a:first-child').clone()).html()
            .replace('gradient\">','\" style="background-image\:url\(\'IMAGE\'\) !important \">'),
            htmlNoticiaImg = regexImgBg.exec( htmlNoticia )[1];

        var htmlNoticiaImgLink = regexImgHref.exec(htmlNoticia)[1],
            htmlNoticiaTitulo = regexTitulo.exec(htmlNoticia)[1],

            htmlPublicacoes = $('<div></div>').append($('.tab-pane#tab-2 > div a:first-child').clone()).html(),
            htmlPublicacoesImg = regexImgPublicacoes.exec(htmlPublicacoes)[1],
            htmlPublicacoesImgLink = regexImgHref.exec(htmlPublicacoes)[1],
            htmlPublicacoesTitulo = regexTitulo.exec(htmlPublicacoes)[1],
            htmlPublicacoesTituloRegex = new RegExp(htmlPublicacoesTitulo, "g"),

            htmlEstatisticas = $('<div></div>').append($('.tab-pane#tab-3 > div a:first-child').clone()).html(),
            htmlEstatisticasImg = regexImg.exec(htmlEstatisticas)[1],
            htmlEstatisticasImgLink = regexImgHref.exec(htmlEstatisticas)[1],
            htmlEstatisticasTitulo = regexTitulo.exec(htmlEstatisticas)[1],
            htmlEstatisticasTituloRegex = new RegExp(htmlEstatisticasTitulo, "g"),

            htmlEventos = $('<div></div>').append($('.tab-pane#tab-4 > div a:first-child').clone()).html(),
            htmlEventosImg = ((htmlEventos != "")?regexImg.exec(htmlEventos)[1]:""),
            htmlEventosImgLink = ((htmlEventos != "")?regexImgHref.exec(htmlEventos)[1]:""),
            htmlEventosTitulo = ((htmlEventos != "")?regexH3.exec(htmlEventos)[1]:""),
            htmlEventosTituloRegex = ((htmlEventos != "")?new RegExp(htmlEventosTitulo, "g"):""),
            htmlEventosLocal = ((htmlEventos != "")?regexLocal.exec(htmlEventos)[1]:""),
            htmlEventosData = ((htmlEventos != "")?regexData.exec(htmlEventos)[1]:""),

            htmlSites = $('<div></div>').append($('.tab-pane#tab-5 > div a:first-child').clone()).html();
            htmlSites = htmlSites.replace(/\n /g, '');
        var htmlSitesImgLink = regexImgHref.exec(htmlSites)[1],
            htmlSitesTitulo = regexTituloSites.exec(htmlSites)[1],
            htmlSitesTituloRegex = new RegExp(htmlSitesTitulo, "g");

        $(document).on('click', '.next-page', function () {
            var id =  $(this).attr('id');

            addItensAjax($(this),id);

        });

        var addItensAjax = function(t, id){
            //console.log(id);

            if(id == "nextPageNoticia"){
                var urlCurrent = settings.urlNoticias;
                nro_objetos_Noticias = nro_objetos_Noticias + settings.qntNoticias;
                nro_objetos = nro_objetos_Noticias;


            } else if (id == "nextPagePublicacoes"){
                var urlCurrent = settings.urlPublicacoes;
                nro_objetos_Publicacoes = nro_objetos_Publicacoes + settings.qntPublicacoes;
                nro_objetos = nro_objetos_Publicacoes;

            } else if (id == "nextPageEstatisticas") {
                var urlCurrent = settings.urlEstatisticas;
                nro_objetos_Estatisticas = nro_objetos_Estatisticas + settings.qntEstatisticas;
                nro_objetos = nro_objetos_Estatisticas;


            } else if (id == "nextPageEventos") {
                var urlCurrent = settings.urlEventos;
                nro_objetos_Eventos = nro_objetos_Eventos + settings.qntEventos;
                nro_objetos = nro_objetos_Eventos;


            } else if (id == "nextPageSites") {
                var urlCurrent = settings.urlSites;
                nro_objetos_Sites = nro_objetos_Sites + settings.qntSites;
                nro_objetos = nro_objetos_Sites;

            }

            var url = urlCurrent + "?nro_objetos=" +nro_objetos+ "&plugin_id=" + settings.urlPluginId;

            $.ajax({
                type: "GET",
                url: url,
                dataType: "json",
                crossDomain:true,
                cache: false,
                success: function (data) {
                    //console.log(data);
                    if (!data[0]['error']) {
                        addItens(t,id,data,nro_objetos);
                    } else {
                        t.hide();
                    }

                },
                error:function(){
                    t.hide();
                }
            });

        };

        var addItens = function(t, id, data, nro_objetos){

            //console.log("addItens: " +id + data);

            if(id == "nextPageNoticia"){

                for(i=0; i < data.length ; i++){

                    var htmlNoticiaFinal = htmlNoticia


                    if(data[i].imagem){//se tiver imagem
                        var htmlNoticiaFinal = htmlNoticia
                        .replace(htmlNoticiaImgLink, data[i].url)
                        .replace(htmlNoticiaTitulo, data[i].noticia)
                        .replace(htmlNoticiaImg, "'"+data[i].imagem+"'");
                    } else {
                        var htmlNoticiaFinal = htmlNoticia
                        .replace(htmlNoticiaImgLink, data[i].url)
                        .replace(htmlNoticiaTitulo, data[i].noticia)
                        .replace('art-lg-img', 'art-lg-img gradient');
                    }


                    //console.log(htmlNoticiaFinal);
                    t.parents('.tab-pane').find('> div:first-child').append(htmlNoticiaFinal);

                }
                if (nro_objetos + settings.qntNoticias >= settings.totalNoticias ){ //se for ultima leva de itens, some o carregar mais
                    t.hide()
                }
            } else if (id == "nextPagePublicacoes"){
                for(i=0; i < data.length ; i++){
                    var htmlNoticiaFinal = htmlPublicacoes
                        .replace(htmlPublicacoesImg, data[i].imagem)
                        .replace(htmlPublicacoesImgLink, data[i].url)
                        .replace(htmlPublicacoesTituloRegex, data[i].titulo);

                    //console.log(htmlPublicacoesTitulo);
                    t.parents('.tab-pane').find('> div:first-child').append(htmlNoticiaFinal);

                }

                if (nro_objetos + settings.qntPublicacoes >= settings.totalPublicacoes ){ //se for ultima leva de itens, some o carregar mais
                    t.hide();
                }
            } else if (id == "nextPageEstatisticas") {

                for (i = 0; i < data.length; i++) {
                    var htmlNoticiaFinal = htmlEstatisticas
                        .replace(htmlEstatisticasImg, data[i].imagem)
                        .replace(htmlEstatisticasImgLink, data[i].url)
                        .replace(htmlEstatisticasTituloRegex, data[i].titulo);

                    //console.log(htmlPublicacoesTitulo);
                    t.parents('.tab-pane').find('> div:first-child').append(htmlNoticiaFinal);

                }
                if (nro_objetos + settings.qntEstatisticas >= settings.totalEstatisticas){ //se for ultima leva de itens, some o carregar mais
                    t.hide();
                }
            } else if (id == "nextPageEventos") {

                for (i = 0; i < data.length; i++) {
                        var htmlNoticiaFinal = htmlEventos
                            .replace(htmlEventosImg, data[i].imagem)
                            .replace(htmlEventosImgLink, data[i].url)
                            .replace(htmlEventosTituloRegex, data[i].titulo)
                            .replace(htmlEventosLocal, data[i].local)
                            .replace(htmlEventosData, data[i].data);

                        //console.log(htmlPublicacoesTitulo);
                        t.parents('.tab-pane').find('> div:first-child').append(htmlNoticiaFinal);

                }

                if (nro_objetos + settings.qntEventos >= settings.totalEventos ){ //se for ultima leva de itens, some o carregar mais
                    t.hide();
                }
            } else if (id == "nextPageSites") {
                for (i = 0; i < data.length; i++) {

                    var htmlNoticiaFinal = htmlSites
                        .replace(htmlSitesImgLink, data[i].url)
                        .replace(htmlSitesTituloRegex, data[i].site);

                    //console.log(htmlPublicacoesTitulo);
                    t.parents('.tab-pane').find('> div:first-child').append(htmlNoticiaFinal);

                }
                if (nro_objetos + settings.qntSites <= settings.totalSites ){ //se for ultima leva de itens, some o carregar mais
                    t.hide();
                }
            }


        }


    };

    $.fn.anchorAnimate = function(id) {

        var $target = $(id);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - 100
        }, 900, 'swing');

    };

	$.fn.painelNoticiasLoad = function(options) {

		$(window).load(function () {

			var _painelNoticiaLoad = '.painel-noticia-load';
			var _nextPage = '.next-pg';
			var _paginacao = '.pagination';
			var _arrowNext = '.arrow-next';
			var _arrowPrev = '.arrow-prev';
			var _classeCarregarMais = '.painel-noticia-load-carregar-mais';
			var _newslist = '.news-list';
			var _loading = '.loading-circle';
			var dataUrl = $('.arrow-prev').attr('data-url');
			var firsLoad = true;
			var paginationType = ( $( _classeCarregarMais ).length > 0 ) ? 'carregarMais' : 'paginacao' ;

			//alert(paginationType)

			//console.log('paginationType: ', paginationType );

			$(_loading).hide('fast');
			$(_newslist).fadeIn('fast');

			var carregarItens = function (_this, dataUrl, pluginId, dist, paginacaoTipo,_primeiroCarregamento) {

				$.ajax({
					url: dataUrl,
					type: 'GET',
					crossDomain: true,
					success: function (data) {


						var proximaPagina = dataUrl.split('/?page=').pop();
						window.location.hash = 'pagina=' + proximaPagina;

						console.log(data.content)

						if (paginationType == 'carregarMais') {

							_this.removeClass('loading');
							_this.attr('data-url', data.next_page);

							dist.append(data.content);

							//verificar se e ultima pagina
							if (!data.next_page) {
								_this.fadeOut()
							}

						} else {

							//verifica se nao e a paginacao atual
							if (!_this.hasClass('active') || _primeiroCarregamento) {

								$('html, body').stop().animate({
									'scrollTop': 100
								}, 900, 'swing');

								atualizarPaginacao();

								dist.html('');
								dist.append(data.content);


							}
						}


					}
				})
			};



			var verificarCarregamento = function (_this, dataUrl, pluginId, dist, paginacaoTipo, _primeiroCarregamento) {

				if (paginacaoTipo == 'carregarMais') {
					carregarItens(_this, dataUrl, pluginId, dist, paginacaoTipo);
					_this.addClass('loading');
				} else {
					//verifica se e a paginacao atual
					if (!_this.hasClass('active') || _primeiroCarregamento) {
						carregarItens(_this, dataUrl, pluginId, dist, paginacaoTipo,_primeiroCarregamento);
					}
				}
			};

			if (paginationType == 'paginacao') {

				var paginacaoArrow = function () {
					var paginacaoHash = window.location.hash;
					var proximaPagina = Number(paginacaoHash.split('pagina=').pop()) + 1;
					var anteriorPagina = Number(paginacaoHash.split('pagina=').pop()) - 1;
					var totalPagina = Number($('.pagination li').length) - 2;

					//verifica se tem parametro
					var dataUrlNext = dataUrl.split('=')[0] + '=' + (proximaPagina);
					var dataUrlPrev = dataUrl.split('=')[0] + '=' + (anteriorPagina);
					var hrefNext = '#pagina' + proximaPagina;
					var hrefPrev = '#pagina' + anteriorPagina;

					if (anteriorPagina <= 0) {
						$(_arrowPrev).addClass('disable');
					} else {
						$(_arrowPrev).removeClass('disable');
						$(_arrowPrev).attr('data-url', dataUrlPrev);
						$(_arrowPrev).attr('href', hrefPrev);
					}

					if (proximaPagina > totalPagina) {
						$(_arrowNext).addClass('disable');
					} else {
						$(_arrowNext).removeClass('disable');
						$(_arrowNext).attr('data-url', dataUrlNext);
						$(_arrowNext).attr('href', hrefNext);
					}
				}


				var primeiroCarregamento = function () {

					var paginacaoHash 			= window.location.hash,
						_primeiroCarregamento 	= true;

					if( paginacaoHash.indexOf('pagina') >= 0 ){
						var paginacaoHashNum = Number(paginacaoHash.split('pagina=').pop())
					} else {
						var paginacaoHashNum = 1;
					}

					var paginacaoAtualChild = paginacaoHashNum + 1;

					var _this = $('.painel-noticia-load .pagination li:nth-child(' + paginacaoAtualChild + ') a'),
						dataUrl = _this.attr('data-url'),
						pluginId = dataUrl.split(/noticias\/|\/\?/)[1],
						dist = $('.cms-plugin-' + pluginId + '.news-list'),
						paginacaoTipo = ( $(this).hasClass(_nextPage) ) ? 'carregarMais' : 'paginacao';

					verificarCarregamento(_this, dataUrl, pluginId, dist, paginacaoTipo, _primeiroCarregamento);

				}
				primeiroCarregamento();



			}






			var atualizarPaginacao = function() {

				//verificar tipo de paginacao de cada plugin
				//$('.painel-noticia-load').each(function () {

					//verificar se e carregar mais
					if ( paginationType == 'carregarMais' ) {//carregarmais
						$(_nextPage).fadeIn();
					} else {//paginacao
						$(_paginacao).fadeIn();

						paginacaoArrow();

						var paginacaoHash = window.location.hash;
						var proximaPagina = Number(paginacaoHash.split('pagina=').pop()) + 1;

						$(_paginacao + ' li a.active').removeClass('active')

						if( paginacaoHash.indexOf('pagina') >= 0 ){
							$(_paginacao + ' li:nth-child('+ proximaPagina +') a ').addClass('active');
						} else {
							$(_paginacao + ' li:nth-child(2) a ').addClass('active');
						}

					}

				//});
			};
			atualizarPaginacao();

			$(document).on('click', '.painel-noticia-load .next-pg', function (e) {

				e.preventDefault();

				// pega variaveis necessarias do DOM
				var _this = $(this),
					dataUrl = $(this).attr('data-url'),
					pluginId = dataUrl.split(/noticias\/|\/\?/)[1],
					dist = $('.cms-plugin-' + pluginId + '.news-list'),
					paginacaoTipo = ( $(this).hasClass(_nextPage) ) ? 'carregarMais' : 'paginacao',
					_primeiroCarregamento = false;

					verificarCarregamento(_this, dataUrl, pluginId, dist, paginacaoTipo, _primeiroCarregamento)

			});

		})

	};

	$.fn.voltarAoTopo = function (options) {
	    var _this = this;

		//click
		_this.on('click',function(){
			$('html,body').stop().animate({
				'scrollTop': 0
			},900)
		});

		//scroll
		$(window).scroll(function (e) {
			addBotao();
		});

		var addBotao = function () {

			var _scroltTop = $(this).scrollTop();

			if( _scroltTop >= 180 && _this.is(':hidden') ){
				_this.fadeIn();
			} if ( _scroltTop < 180 && _this.is(':visible') ) {
				_this.fadeOut();
			}
		};
		addBotao();
	};

    $.fn.helpText = function(options) {

		var tooltipHelperClass = 'tooltip-helper';
		var tooltipHelperActive = 'plugin-tooltip-helper-active';
		var classeIconeDefault = "fa fa-";
		var htmlBase = "<div class='"+tooltipHelperClass+"' [style_cor_fundo]><span class='after' [style_cor_seta]></span><span class='icon " + classeIconeDefault + "' [style_cor_icone]></span><p class='text' [style_cor_texto]>TEXTO</p></div>";
		if ( 'icone' in options ) {
		    tipoIcone = options['icone'].split('-')[0]; //glyphicon-align-center
		    classesIcone = tipoIcone + " " + options['icone'];
		    htmlBase = htmlBase.replace(classeIconeDefault, classesIcone);
		}
		var styleStrCorFonte = "";
		var styleStrCorFundo = "";
		if ( 'cor_fundo' in options ){
		    if ('cor_fonte' in options ){ //cor fundo e fonte
		        var cor_fundo = options['cor_fundo'];
		        var cor_fonte = options['cor_fonte'];
		        styleStrCorFundo = "style='background:"+ cor_fundo +" !important;'";
		        styleStrCorFonte = "style='color:"+ cor_fonte +" !important;'";
		    }else{ //só cor fundo
		        var cor_fundo = options['cor_fundo'];
		        styleStrCorFundo = "style='background:"+ cor_fundo +" !important;'"
		    }
		}else{
		    if ('cor_fonte' in options ){ //só fonte
		        var cor_fonte = options['cor_fonte'];
		        styleStrCorFonte = "style='color:"+ cor_fonte +" !important;'";
		    }
		}
		htmlBase = htmlBase.replace("[style_cor_fundo]", styleStrCorFundo);
		htmlBase = htmlBase.replace("[style_cor_icone]", styleStrCorFonte);
		htmlBase = htmlBase.replace("[style_cor_texto]", styleStrCorFonte);

		// console.log(htmlBase);

		$(this).on('mouseenter', function () {//hover no helptext plguin & se for na tela de edicao, nunca no publicado
			var el = $(this);
			var dataTooltipHelper = $(this).attr('data-TooltipHelper');
			// console.log('mouseenter');

			if( dataTooltipHelper ){
				el.addClass(tooltipHelperActive);//add active no elemento

				var elTop = ( el.offset().top <= 150 );

				addHtml(el,dataTooltipHelper, elTop)
			}

		});
		$(this).on('mouseleave', function () {
			$('.'+tooltipHelperActive).removeClass(tooltipHelperActive);//remover todos os actives da página
			$(this).find('.'+tooltipHelperClass).remove();//remove a o elemento tooltiphelper
			// console.log('mouseleave	');
		});

		var addHtml = function(el, dataTooltipHelper, elTop) {
			var htmlFinal = htmlBase.replace('TEXTO', dataTooltipHelper);

			if (elTop){//se o elemento estiver no topo da página
			    if ( 'cor_fundo' in options ){
			        seta_cor_fundo = "style='border-bottom:25px solid "+options['cor_fundo']+";'";
			    }else{
			        seta_cor_fundo = "";
			    }
			    htmlFinal = htmlFinal.replace("[style_cor_seta]", seta_cor_fundo);
				htmlFinal = htmlFinal.replace(tooltipHelperClass, tooltipHelperClass + ' el-top');
			}else{
			    if ( 'cor_fundo' in options ){
			        seta_cor_fundo = "style='border-top:25px solid "+options['cor_fundo']+";'";
			    }else{
			        seta_cor_fundo = "";
			    }
			    htmlFinal = htmlFinal.replace("[style_cor_seta]", seta_cor_fundo);
			}

			el.prepend(htmlFinal);
			//console.log(htmlFinal);
		}

	};

    $.fn.cabecalhoTelaCheia = function(options) {
		$(window).load(function() {
			//altura do navegador
			var elAlturaNavegador = $('.altura-navegador');

			var addAlturaNoPlugin = function () {
				if (elAlturaNavegador) {
					var alturaNavegadorGeralEl = elAlturaNavegador.children('.geral');
					var windowHeight = $(window).height();
					var topBarHeight = $('.top-bar').height();
					var topBarCms = ( $('.cms-toolbar-trigger.cms-toolbar-trigger-expanded').length ) ? $('.cms-toolbar').height() : 0;//verifica barra do djangoCMS
					var finalHeight = (windowHeight - topBarHeight ) - topBarCms;

					alturaNavegadorGeralEl.css({
						'height': finalHeight,
						'min-height': 'inherit'
					});

					//executa quando for resize vertical
					var height = $(window).height();
					$(window).resize(function () {

						if ($(window).height() == height) return;//verifica se é resize altura
						height = $(window).height();//atualiza altura
						addAlturaNoPlugin();

					})
				}

			};
			addAlturaNoPlugin();

			$(document).on('click','.proxima-secao', function (e) {
				e.preventDefault();

                var ancora = $('.proxima-secao').position().top + 100;

                $('html, body').animate({
                 scrollTop: ancora
                 }, 1000);
			})

		})
	};



    //---------------- legado ---------------//

    //comeco galeria /*
    (function(){

        if( $('#legado-cms-3mw .galeria').length ){

            var total_images = $(".galeria .thumbs img").length;
            function setOpacityThumb(query) {
                query = query ? query : "[data-index=1]"
                $(".galeria .thumbs img").css({ opacity: 0.6 });
                $(".galeria .thumbs img" + query).css({ opacity: 1 });
            }
            function setCurrentImage(button) {
              if ( button == 'next') {
                var next_index = parseInt($(".galeria .pic").data('atual')) + 1;
              }
              else if ( button == 'prev' ) {
                var next_index = parseInt($(".galeria .pic").data('atual')) - 1;
              }
              if ( next_index > total_images ) {
                  next_index = 1;
                  $(".galeria .thumbs ul").css('left', 0)
              } else if ( next_index < 1 ) {
                  next_index = total_images;
              }
              var __query = "[data-index=" + next_index +"]";
              var __image = $(".galeria .thumbs img" + __query);
              var __embed = __image.data("embed");
              /*
               * Set Image Counter
               */
              $(".galeria .counter .active").text(__image.data('index'));
              /*
               * Set Image Description
               */
              $(".galeria .img-desc p:eq(0)").text(__image.data('desc'));
              /*
               * Set Image Author
               */
              var __author = __image.data('cred');
              if ( __author ) {
                  $(".galeria .credito").show();
                  $(".galeria .autor").text( __author );
              } else {
                  $(".galeria .credito").hide();
              };
              /*
               *
               */
              $(".galeria .pic").fadeTo('fast', 0.3, function(){
                  var $current_image = $(".galeria .pic");
                  if ( __embed ) {
                      $current_image.html( decodeURIComponent( __embed ) );
                      $current_image.fadeTo('slow', 1);
                  } else {
                      $current_image.html($("<img>").attr("src", __image.data('img')));
                      $current_image.fadeTo('slow', 1);
                  }
                  $current_image.data('atual', next_index);
              });

              setOpacityThumb(__query);
            };
            setOpacityThumb();


            $(".galeria .pic-prev").on("click", function(e) {
                e.preventDefault();
                setCurrentImage('prev');
            });

            $(".galeria .pic-next").on("click", function(e) {
                e.preventDefault();
                setCurrentImage('next');
            });
            /*
             * Galeria
             * Michel Lacerda
             */


            $(".galeria .thumbs img").each(function(i, e) {
              var j = new Image();
              j.src = $(e).data("img");
            });
            $(".galeria .expand").on("click", function() {
              $(".modal-galeria img").attr("src", $(this).data("img-url"));
              $(".modal-galeria").modal();
            });
            $(".galeria .thumbs img").on("click", function() {
              var new_src = $(this).data("img");
              var img_index = $(this).data('index');
              var embed = $(this).data("embed");
              setOpacityThumb("[data-index=" + img_index + "]");
              $(".galeria .pic").fadeTo('fast', 0.3, function(){
                if (embed) {
                  $(this).html(decodeURIComponent(embed));
                  $(this).fadeTo('slow', 1);
                } else {
                  $(this).html($("<img>").attr("src", new_src));
                  $(this).data('atual', img_index);
                  $(this).fadeTo('slow', 1);
                }
              });
              $(".galeria .counter .active").text($(this).data("index"));
              $(".galeria .img-desc p:eq(0)").text($(this).data("desc"));
              var img_gde = $(this).data("img-gde");
              if (img_gde) {
                $(".galeria .expand").data("img-url", img_gde).show();
              } else {
                $(".galeria .expand").hide();
              }
              var cred = $(this).data("cred");
              if (cred) {
                $(".galeria .credito").show();
                $(".galeria .autor").text(cred);
              } else {
                $(".galeria .credito").hide();
              }
            });
            $(".galeria .thumbs img:eq(0)").click();
            $(".galeria .thumbs .prev").on("click", function(e) {
              e.preventDefault();
              setCurrentImage('prev');
              if (parseInt($(".galeria .thumbs ul").css("left"), 10) <= -1){
                var destino =  parseInt($(".galeria .thumbs ul").css("left"), 10) + 120;
                destino = destino > 0 ? 0: destino;
                $(".galeria .thumbs ul").css("left", destino);
              }
            });
            $(".galeria .thumbs .next").on("click", function(e) {
              e.preventDefault();
              setCurrentImage('next');
              if (parseInt($(".galeria .thumbs ul").css("left"), 10) >= -110 * $(".galeria .thumbs li").length + 420){
                $(".galeria .thumbs ul").css("left", parseInt($(".galeria .thumbs ul").css("left"), 10) - 120);
              }
            });
        }

    })();
    //fim galeria

}(window,jQuery));

var MenuCustomizavel = function(dados_customizados){
    this.configuracoes = dados_customizados;
    this.objMenu = $(".menu-customizavel");
    this.objMenuTopo = $(".menu-customizavel.topo-menu");
    this.objMenuTopoFechar = $(".menu-customizavel.topo-menu-close");
}

MenuCustomizavel.prototype.montarTela = function(){
    this.objMenu.on('click', '.topo-menu', function(e) {
        $(".menu-customizavel .topo-menu").hide();
        $(".menu-customizavel .itens-menu").removeClass( "hide-menu-customizavel" ).addClass( "show-menu-customizavel" );
    });

    this.objMenu.on('click', '.topo-menu-close i', function(e) {
        $(".menu-customizavel .itens-menu").removeClass( "show-menu-customizavel" ).addClass( "hide-menu-customizavel" );
        setTimeout(function(){$(".menu-customizavel .topo-menu").show()}, 500);
    });

    this.objMenu.on('click', '.i-collapse', function(e) {
        id_show_hide = "#" + this.id.replace("-i-", "-li-");
        if ($(id_show_hide).is(":visible")){
             $(this).removeClass( "fa-chevron-up" ).addClass( "fa-chevron-down" );
        }else{
             $(this).removeClass( "fa-chevron-down" ).addClass( "fa-chevron-up" );
        }
        $(id_show_hide).slideToggle("slow");
    });


}

/* back-top function */
$(function(){
	$(document).on( 'scroll', function(){
		if ($(window).scrollTop() >100) {
			$('.back-top').addClass('show');
		} else {
			$('.back-top').removeClass('show');
		}
	});
	$('.back-top, #back-top').on('click', scrollToTop);
});

function scrollToTop() {
	verticalOffset = typeof(verticalOffset) != 'undefined' ? verticalOffset : 0;
	element = $('body');
	offset = element.offset();
	offsetTop = offset.top;
	$('html, body').animate({scrollTop: offsetTop}, 400);
}